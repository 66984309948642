<template>
  <div
    ref="slidebox"
    class="slidebox d-flex flex-wrap align-items-center px-0"
    :class="{
      'flex-row-reverse': !isTextLeft,
      'animate-fade': !useChildrenSlideIn,
      'animate-fade--show': show && !useChildrenSlideIn,
      'animate-slide-in overflow-visible': useChildrenSlideIn,
      'animate-slide-in--show': show && useChildrenSlideIn,
    }"
  >
    <div
      class="col-12 col-md-6 px-3 px-md-0 d-flex align-items-center"
      :class="isTextLeft ? 'justify-content-end' : ''"
    >
      <div
        class="slidebox__text px-0"
        :class="isTextLeft ? 'ps-md-3 pe-md-8' : 'ps-md-8 pe-md-3'"
      >
        <h2 class="fs-2 mb-5 text-uppercase text-center text-md-start">
          {{ component.slide.title }}
        </h2>
        <p class="fs-5 text-center text-md-start">{{ component.slide.text }}</p>
      </div>
    </div>
    <div class="col-12 col-md-6 slidebox__image position-relative">
      <strapi-slide-box-slider
        v-if="images.length > 1"
        :images="images"
        class="slidebox__slider px-3 px-md-0"
      />
      <div
        v-else-if="images.length === 1"
        class="slidebox__image-wrapper position-relative px-0"
        :class="{ 'd-flex align-items-center': inContainer }"
      >
        <strapi-image
          :image="images[0]"
          :alt="images[0].alternativeText"
          as-background
          class="slidebox__image"
          :class="{
            'gp-shadow': imageHasShadow,
            'w-100 h-auto position-static': inContainer,
          }"
        />
      </div>
      <!-- float over image -->
      <strapi-image
        v-if="parallaxImage != null"
        :style="{ transform: translatePos }"
        :image="parallaxImage"
        :alt="parallaxImage.alternativeText"
        fluid
        class="position-absolute top-0 start-0"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  useElementBounding,
  useIntersectionObserver,
  useScroll,
} from '@vueuse/core';
import {
  type StrapiHomePageSlideBoxComponent,
  StrapiHomePageTextPosition,
} from '~/apollo/types/types';
import StrapiImage from '~/components/strapi/StrapiImage.vue';

// Not pretty, but IMHO total overblown if we put these in strapi as well.
// The key is defined in strapi, so adding new panels will default in no
// animation until it is added here as well. The first element in the
// array is the x position relative to the scroll width, the second
// is the y position. You can use negative numbers to change direction.
// Higher numbers mean faster animations. To define an offset, use the
// overlay image with a transparent background.
const animations = {
  games: [0, 2],
  online: [1.5, 1],
};

export default defineComponent({
  name: 'StrapiSlideBox',
  components: {
    StrapiImage,
  },
  props: {
    component: {
      type: Object as PropType<StrapiHomePageSlideBoxComponent>,
      required: true,
    },
    inContainer: {
      type: Boolean,
      default: false,
    },
    imageHasShadow: {
      type: Boolean,
      default: true,
    },
    useChildrenSlideIn: {
      type: Boolean,
      default: false,
    },
    appearThreshold: {
      type: Number,
      default: 0.5,
    },
  },
  setup(props) {
    const slidebox = ref<HTMLElement | null>(null);
    const currentSlideIndex = ref<number>(0);
    const slideboxPos = ref<number>(-1);
    const show = ref<boolean>(false);

    const isTextLeft = computed(
      () =>
        props.component.slide.textPosition === StrapiHomePageTextPosition.Left,
    );

    const { top, height } = useElementBounding(slidebox);
    const translatePos = computed(() => {
      if (props.component.privateTitle in animations && show.value) {
        const [x, y] = animations[props.component.privateTitle];
        return `translate(${slideboxPos.value * x}px, ${
          slideboxPos.value * y
        }px)`;
      }
      return 'translate(0, 0)';
    });

    // shorthand for images, these are the carousel slides
    const images = computed(() => props.component.slide.images);

    // We just take the first parallax image for the first carousel slide
    const parallaxImage = computed(() => props.component.slide.parallaxImage);

    const getSlideboxPos = () => {
      const scrollPos = top.value + height.value;
      const scrollLength = window.innerHeight + height.value;
      const percent = Math.floor((scrollPos / scrollLength) * 100);

      if (percent > 0 && percent <= 100) {
        slideboxPos.value = percent;
      }
    };

    // We do not have 'document' in SSR
    if (process.client) {
      useScroll(document, {
        throttle: 10,
        onScroll: () => {
          // Determine the position of the image in percent
          getSlideboxPos();
        },
      });
    }

    // Show slidebox when visible and stay visible when leaving the viewport.
    useIntersectionObserver(
      slidebox,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          show.value = true;
        }
      },
      { threshold: props.appearThreshold },
    );

    onMounted(() => {
      getSlideboxPos();
    });

    return {
      isTextLeft,
      translatePos,
      slidebox,
      currentSlideIndex,
      show,
      images,
      parallaxImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.animate {
  &-fade {
    transition-property: opacity;
    transition-duration: 0.8s;
    transition-delay: 0.3s;
    opacity: 0;

    &--show {
      opacity: 1;
    }
  }

  &-slide-in {
    .slidebox {
      &__text {
        opacity: 0;
        transform: translateY(50px);
        transition:
          opacity 0.5s ease 0.5s,
          transform 0.5s ease 0.5s;
      }

      &__image {
        opacity: 0;
        transform: translateX(50px);
        transition:
          opacity 0.5s ease 1s,
          transform 0.5s ease 1s;
      }
    }

    &.flex-row-reverse {
      .slidebox__image {
        transform: translateX(-50px);
      }
    }

    &--show {
      .slidebox {
        &__text {
          opacity: 1;
          transform: translateY(0);
        }

        &__image {
          opacity: 1;
          transform: translateX(0) !important;
        }
      }
    }
  }
}

.slidebox {
  // opacity: 0;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 0;
  }

  &__text {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 550px;
    }
  }

  &__image {
    border-radius: 0 !important;

    @include media-breakpoint-up(lg) {
      border-radius: 5px !important;
    }
  }

  &__slider,
  &__image-wrapper {
    height: 16rem;

    @include media-breakpoint-up(sm) {
      height: 28rem;
    }

    @include media-breakpoint-up(md) {
      height: 16rem;
    }

    @include media-breakpoint-up(lg) {
      height: 28rem;
    }
  }

  .carousel-control {
    width: 50px;

    &-next {
      right: 1rem;
    }

    &-prev {
      left: 1rem;
    }
  }
}

.sustainability-index-page {
  .slidebox {
    &__image {
      aspect-ratio: 8/5;
      box-shadow: none;

      &-wrapper {
        height: 16rem;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 456px;
      }
    }

    @include media-breakpoint-up(xxl) {
      gap: 5em;
    }
  }
}
</style>
