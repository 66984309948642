<template>
  <div class="slidebox__slider">
    <div
      ref="sliderContainer"
      class="slidebox__slider-container row m-0 flex-nowrap h-100"
    >
      <div
        v-for="image in images"
        :key="image.id"
        ref="sliderItems"
        class="slidebox__slider-item me-2 me-lg-8 h-100 rounded position-relative"
        :class="{ 'slidebox__slider-item--two-items': images.length === 2 }"
      >
        <strapi-image
          class="rounded h-100 gp-shadow"
          :image="image"
          :alt="image.alternativeText"
          as-background
        />
      </div>
    </div>

    <!-- controls -->
    <button
      v-if="activeItemIndex > 0"
      aria-label="Previous"
      class="slidebox__slider-control slidebox__slider-control-prev btn btn-dark position-absolute d-none d-lg-block top-50 translate-middle-y rounded"
      type="button"
      @click="previousItem"
    >
      <fa-icon
        class="text-rhino lh-lg"
        :icon="faChevronLeft"
        aria-hidden="true"
      />
    </button>

    <button
      v-if="activeItemIndex < images.length - 1 && !arrivedState.right"
      aria-label="Next"
      class="slidebox__slider-control slidebox__slider-control-next btn btn-dark position-absolute d-none d-lg-block top-50 translate-middle-y rounded"
      :class="{
        'slidebox__slider-control-next--two-items': images.length === 2,
      }"
      type="button"
      @click="nextItem"
    >
      <fa-icon
        class="text-rhino lh-lg"
        :icon="faChevronRight"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script lang="ts">
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import type { StrapiImage } from '~/apollo/types/types';
import useWindowResize from '~/composables/useWindowResize';

export default defineComponent({
  props: {
    images: {
      type: Array as PropType<Array<StrapiImage>>,
      default: () => [],
    },
  },
  setup() {
    const activeItemIndex = ref<number>(0);
    const sliderContainer = ref<HTMLDivElement>(null);
    const sliderItems = ref<Array<HTMLDivElement>>([]);
    const { arrivedState } = useScroll(sliderContainer);

    const scroll = () => {
      let targetScrollPosition = 0;

      if (activeItemIndex.value > 0) {
        targetScrollPosition =
          sliderItems.value[activeItemIndex.value].offsetLeft;
      }

      sliderContainer.value.scrollTo({
        left: targetScrollPosition,
        behavior: 'smooth',
      });
    };

    const previousItem = () => {
      activeItemIndex.value -= 1;
      scroll();
    };

    const nextItem = () => {
      activeItemIndex.value += 1;
      scroll();
    };

    useWindowResize(
      () => {
        activeItemIndex.value = 0;
        scroll();
      },
      { throttle: 100 },
    );

    return {
      faChevronLeft,
      faChevronRight,

      sliderContainer,
      sliderItems,
      activeItemIndex,
      arrivedState,
      previousItem,
      nextItem,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'gportal-theme/scss/colors';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.slidebox__slider {
  &-item {
    min-width: 40%;
    width: auto;
    scroll-snap-align: start;

    @include media-breakpoint-up(lg) {
      min-width: 35%;
    }

    &--two-items {
      min-width: calc(70% + 2rem);
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &-container {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    @include media-breakpoint-up(lg) {
      overflow-x: hidden;
    }
  }

  &-control {
    background-color: $gp-dark-mouse;

    &-next {
      left: calc(70% + 0.5rem);
    }
  }
}
</style>
