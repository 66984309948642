import {
  useWindowSize,
  watchThrottled,
  WatchThrottledOptions,
} from '@vueuse/core';

/**
 * Provides functionality when window size changes. It also supports throttled event handling.
 * Unsuscribing of watch and event happens automatically when component is unmounted.
 *
 * **Gotchas:**
 *   * When setting the `immediate` option to `true`, make sure that the composable is only on client side or pass `process.client` as value for `immediate`. On server side an error will be thrown.
 *   * When accessing element refs in the callback function and setting `immediate` option to true, make sure to check for `null` or `undefined` first before other operations.
 *     In general, if you want to use the template ref in the callback function on component initialization, execute the handler in the `onMounted` hook instead of use `immediate: true` to make sure that the template ref is not null.
 *
 * @param handleWindowResize - The function to call when the window is resized, width and height will be provided as parameters
 * @param options see {@link WatchThrottledOptions}
 * @param target the target window to watch, default is the root window
 *
 * @returns width and height refs of the window, see {@link useWindowSize}
 */
export const useWindowResize = (
  handleWindowResize: (width: number, height: number) => void,
  options: WatchThrottledOptions<boolean>,
  target?: Window,
) => {
  const { width, height } = useWindowSize(target);

  watchThrottled(
    () => width.value,
    () => handleWindowResize(width.value, height.value),
    options,
  );
  watchThrottled(
    () => height.value,
    () => handleWindowResize(width.value, height.value),
    options,
  );

  return {
    width,
    height,
  };
};

export default useWindowResize;
